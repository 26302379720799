var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"container-type":"centered"}},[_c('page-row',[_c('page-column',[_c('echo-form',{attrs:{"title":_vm.$t('repair.title'),"explanation":_vm.$t('repair.explanation'),"model":_vm.model,"form":_vm.form,"submit-action":"REQUEST_REPAIR","data-test":"request-repair"}},[_c('field-set',{attrs:{"legend":_vm.$t('view.visit.title'),"data-test":"visit"}},[_c('input-ucrn',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"ucrn","validators":{
              required: { messageTranslationKey: 'form.ucrn.required' },
              myUcrn: true,
            },"label":_vm.$t('form.ucrn.label'),"data-test":"ucrn"}}),(_vm.vesselForSummary)?_c('ship-summary',{attrs:{"vessel":_vm.vesselForSummary}}):_vm._e(),_c('input-select',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"berthVisitId","validators":{
              required: { messageTranslationKey: 'form.berth.required' },
              notMoerdijk: _vm.visit && { visit: _vm.visit, blocking: true },
            },"label":_vm.$t('form.berth.label'),"placeholder":_vm.$t('form.berth.placeholder'),"options":_vm.berthOptions}})],1),_c('field-separator'),_c('field-set',{attrs:{"legend":_vm.$t('repair.activities.title'),"data-test":"activities"}},[_c('input-select',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"type","validators":{ required: true, maxLength: { length: 2000 } },"label":_vm.$t('repair.activities.type.label'),"options":[
              {
                value: 'Reparatie zonder open vuur of heet werk',
                text: _vm.$t('repair.activities.type.options.notHot'),
              },
              {
                value: 'Reparatie met open vuur of heet werk',
                text: _vm.$t('repair.activities.type.options.hot'),
              },
              {
                value: 'Proefvaren, duw en trekproeven',
                text: _vm.$t('repair.activities.type.options.test'),
              },
              {
                value: 'Werkzaamheden onder water, schroef polijsten, onderwaterborstelen',
                text: _vm.$t('repair.activities.type.options.underwater'),
              },
              {
                value: 'Proefdraaien van motoren/schroeven afgemeerd of voor de kade',
                text: _vm.$t('repair.activities.type.options.testRun'),
              },
              {
                value: 'Sloop',
                text: _vm.$t('repair.activities.type.options.decommission'),
              } ]}}),_c('input-textarea',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"description","validators":{ required: true, maxLength: { length: 2000 } },"label":_vm.$t('repair.activities.nature.label'),"label-hidden":true,"placeholder":_vm.$t('repair.activities.nature.placeholder')}}),_c('input-select',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"locationOnBoard","validators":{ required: true },"label":_vm.$t('repair.activities.location.label'),"options":[
              {
                value: 'Aan opbouw',
                text: _vm.$t('repair.activities.location.options.superstructure'),
              },
              {
                value: 'Achterschip',
                text: _vm.$t('repair.activities.location.options.stern'),
              },
              {
                value: 'In ladingzone',
                text: _vm.$t('repair.activities.location.options.cargoBay'),
              },
              {
                value: 'In machinekamer',
                text: _vm.$t('repair.activities.location.options.machineRoom'),
              },
              {
                value: 'Voorschip',
                text: _vm.$t('repair.activities.location.options.bow'),
              } ]}}),_c('input-textarea',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"locationOnBoardText","validators":{ required: true, maxLength: { length: 512 } },"label":_vm.$t('repair.activities.locationExact.label'),"placeholder":_vm.$t('repair.activities.locationExact.placeholder')}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('input-date',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"from","validators":{ required: true, dateAfterNow: true, dateBeforeField: { key: 'until' } },"label":_vm.$t('repair.activities.start.label'),"config":_vm.startDateConfig}})],1),_c('div',{staticClass:"col-md-6"},[_c('input-date',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"until","validators":{
                  required: true,
                  dateAfterNow: true,
                  dateAfterField: {
                    key: 'from',
                    messageTranslationKey: 'repair.activities.end.message',
                  },
                },"label":_vm.$t('repair.activities.end.label'),"config":_vm.endDateConfig}})],1)]),(_vm.showOwnerPermission)?_c('input-checkbox',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"ownerPermission","validators":{
              required: true,
              is: {
                validValue: true,
                days: this.durationDaysThreshold,
                messageTranslationKey: 'repair.activities.permission.message',
              },
            },"checkbox-label":_vm.$t('repair.activities.permission.label', {
                days: this.durationDaysThreshold,
              }),"data-test":"owner-permission"}}):_vm._e(),_c('input-radio',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"ownCrew","validators":{ required: true },"label":_vm.$t('repair.activities.crew.label'),"options":[
              { value: true, text: _vm.$t('repair.activities.crew.options.true') },
              { value: false, text: _vm.$t('repair.activities.crew.options.false') } ],"inline":true,"data-test":"own-crew"}}),(false === _vm.model.ownCrew)?_c('input-textarea',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"crewDescription","validators":{ required: true, maxLength: { length: 255 } },"label":_vm.$t('repair.activities.crew.label'),"label-hidden":true,"placeholder":_vm.$t('repair.activities.crew.placeholder')}}):_vm._e(),_c('field-separator'),_c('input-yes-no',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"hasMainPropulsionAvailable","question":_vm.$t('repair.other.propulsion'),"validators":{ required: true },"options":[
              { value: true, text: _vm.$t('generic.options.yes') },
              { value: false, text: _vm.$t('generic.options.no') } ],"data-test":"main-propulsion"}}),_c('input-yes-no',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"hasSecurityAndHealthStatement","validators":{ required: true },"options":[
              { value: true, text: _vm.$t('generic.options.yes') },
              { value: false, text: _vm.$t('generic.options.no') } ],"question":_vm.$t('repair.other.safetyStatement.question'),"data-test":"safety-statement"}}),(_vm.model.hasSecurityAndHealthStatement)?_c('input-file',{attrs:{"form":_vm.form,"label":_vm.$t('repair.other.safetyStatement.label'),"model":_vm.model,"validators":{ files: { maxFiles: 10 } },"model-key":"attachments","data-test":"attachment-safety-statement"}}):_vm._e(),_c('input-yes-no',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"hasHazardousSubstancesWithinSafetyParameter","question":_vm.$t('repair.other.hazardousSubstances'),"options":[
              { value: true, text: _vm.$t('generic.options.yes') },
              { value: false, text: _vm.$t('generic.options.no') } ],"validators":{ required: true },"data-test":"dangerous-goods"}}),_c('input-yes-no',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngDrivenShip","validators":{ required: true },"question":_vm.$t('repair.other.lng'),"options":[
              { value: true, text: _vm.$t('generic.options.yes') },
              { value: false, text: _vm.$t('generic.options.no') } ],"data-test":"lng-driven"}})],1),(_vm.model.lngDrivenShip)?_c('field-set',{staticClass:"boxed-fieldset",attrs:{"legend":_vm.$t('repair.lng.title'),"description":_vm.$t('repair.lng.description'),"data-test":"lng-driven"}},[_c('input-checkbox',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngWorkInProximityOfTanks","checkbox-label":_vm.$t('repair.lng.proximityTanks'),"data-test":"proximity-of-tanks"}}),_c('input-number',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngQuantityOnBoard","validators":{
              required: true,
              min: { minValue: 0 },
              max: { maxValue: 20000, messageTranslationKey: 'repair.lng.quantity.message' },
            },"label":_vm.$t('repair.lng.quantity.label'),"append":"m³","max-decimals":0,"placeholder":_vm.$t('repair.lng.quantity.placeholder')}}),_c('input-textarea',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngBunkerTanksLocation","validators":{ required: true, maxLength: { length: 512 } },"label":_vm.$t('repair.lng.location.label'),"label-hidden":false,"placeholder":_vm.$t('repair.lng.location.placeholder')}}),_c('input-number',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngBunkerTanksMBarPressure","validators":{
              required: true,
              min: { minValue: 0 },
              max: { maxValue: 20000, messageTranslationKey: 'repair.lng.quantity.message' },
            },"label":_vm.$t('repair.lng.bunker'),"placeholder":_vm.$t('repair.lng.quantity.placeholder'),"max-decimals":0,"append":"mBar"}}),_c('input-number',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngSafetyValveOpeningsMBarPressure","validators":{
              required: true,
              min: { minValue: 0 },
              max: { maxValue: 20000, messageTranslationKey: 'repair.lng.quantity.message' },
            },"label":_vm.$t('repair.lng.valve'),"placeholder":_vm.$t('repair.lng.quantity.placeholder'),"max-decimals":0,"append":"mBar"}}),_c('input-checkbox',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngBoilOffControlOperational","checkbox-label":_vm.$t('repair.lng.boilOff'),"data-test":"boil-off-control-operational"}}),_c('input-checkbox',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"lngEmissionPreventionMeasuresTaken","checkbox-label":_vm.$t('repair.lng.emission'),"data-test":"emission-prevention"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }