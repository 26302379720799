<template>
  <page container-type="centered">
    <page-row>
      <page-column>
        <echo-form
          :title="$t('repair.title')"
          :explanation="$t('repair.explanation')"
          :model="model"
          :form="form"
          submit-action="REQUEST_REPAIR"
          data-test="request-repair"
        >
          <field-set :legend="$t('view.visit.title')" data-test="visit">
            <input-ucrn
              :form="form"
              :model="model"
              model-key="ucrn"
              :validators="{
                required: { messageTranslationKey: 'form.ucrn.required' },
                myUcrn: true,
              }"
              :label="$t('form.ucrn.label')"
              data-test="ucrn"
            />

            <ship-summary v-if="vesselForSummary" :vessel="vesselForSummary" />

            <input-select
              :form="form"
              :model="model"
              model-key="berthVisitId"
              :validators="{
                required: { messageTranslationKey: 'form.berth.required' },
                notMoerdijk: visit && { visit, blocking: true },
              }"
              :label="$t('form.berth.label')"
              :placeholder="$t('form.berth.placeholder')"
              :options="berthOptions"
            />
          </field-set>

          <field-separator />

          <field-set :legend="$t('repair.activities.title')" data-test="activities">
            <input-select
              :form="form"
              :model="model"
              model-key="type"
              :validators="{ required: true, maxLength: { length: 2000 } }"
              :label="$t('repair.activities.type.label')"
              :options="[
                {
                  value: 'Reparatie zonder open vuur of heet werk',
                  text: $t('repair.activities.type.options.notHot'),
                },
                {
                  value: 'Reparatie met open vuur of heet werk',
                  text: $t('repair.activities.type.options.hot'),
                },
                {
                  value: 'Proefvaren, duw en trekproeven',
                  text: $t('repair.activities.type.options.test'),
                },
                {
                  value: 'Werkzaamheden onder water, schroef polijsten, onderwaterborstelen',
                  text: $t('repair.activities.type.options.underwater'),
                },
                {
                  value: 'Proefdraaien van motoren/schroeven afgemeerd of voor de kade',
                  text: $t('repair.activities.type.options.testRun'),
                },
                {
                  value: 'Sloop',
                  text: $t('repair.activities.type.options.decommission'),
                },
              ]"
            />

            <input-textarea
              :form="form"
              :model="model"
              model-key="description"
              :validators="{ required: true, maxLength: { length: 2000 } }"
              :label="$t('repair.activities.nature.label')"
              :label-hidden="true"
              :placeholder="$t('repair.activities.nature.placeholder')"
            />

            <input-select
              :form="form"
              :model="model"
              model-key="locationOnBoard"
              :validators="{ required: true }"
              :label="$t('repair.activities.location.label')"
              :options="[
                {
                  value: 'Aan opbouw',
                  text: $t('repair.activities.location.options.superstructure'),
                },
                {
                  value: 'Achterschip',
                  text: $t('repair.activities.location.options.stern'),
                },
                {
                  value: 'In ladingzone',
                  text: $t('repair.activities.location.options.cargoBay'),
                },
                {
                  value: 'In machinekamer',
                  text: $t('repair.activities.location.options.machineRoom'),
                },
                {
                  value: 'Voorschip',
                  text: $t('repair.activities.location.options.bow'),
                },
              ]"
            />

            <input-textarea
              :form="form"
              :model="model"
              model-key="locationOnBoardText"
              :validators="{ required: true, maxLength: { length: 512 } }"
              :label="$t('repair.activities.locationExact.label')"
              :placeholder="$t('repair.activities.locationExact.placeholder')"
            />

            <div class="row">
              <div class="col-md-6">
                <input-date
                  :form="form"
                  :model="model"
                  model-key="from"
                  :validators="{ required: true, dateAfterNow: true, dateBeforeField: { key: 'until' } }"
                  :label="$t('repair.activities.start.label')"
                  :config="startDateConfig"
                />
              </div>
              <div class="col-md-6">
                <input-date
                  :form="form"
                  :model="model"
                  model-key="until"
                  :validators="{
                    required: true,
                    dateAfterNow: true,
                    dateAfterField: {
                      key: 'from',
                      messageTranslationKey: 'repair.activities.end.message',
                    },
                  }"
                  :label="$t('repair.activities.end.label')"
                  :config="endDateConfig"
                />
              </div>
            </div>

            <input-checkbox
              v-if="showOwnerPermission"
              :form="form"
              :model="model"
              model-key="ownerPermission"
              :validators="{
                required: true,
                is: {
                  validValue: true,
                  days: this.durationDaysThreshold,
                  messageTranslationKey: 'repair.activities.permission.message',
                },
              }"
              :checkbox-label="
                $t('repair.activities.permission.label', {
                  days: this.durationDaysThreshold,
                })
              "
              data-test="owner-permission"
            />

            <input-radio
              :form="form"
              :model="model"
              model-key="ownCrew"
              :validators="{ required: true }"
              :label="$t('repair.activities.crew.label')"
              :options="[
                { value: true, text: $t('repair.activities.crew.options.true') },
                { value: false, text: $t('repair.activities.crew.options.false') },
              ]"
              :inline="true"
              data-test="own-crew"
            />

            <input-textarea
              v-if="false === model.ownCrew"
              :form="form"
              :model="model"
              model-key="crewDescription"
              :validators="{ required: true, maxLength: { length: 255 } }"
              :label="$t('repair.activities.crew.label')"
              :label-hidden="true"
              :placeholder="$t('repair.activities.crew.placeholder')"
            />

            <field-separator />

            <input-yes-no
              :form="form"
              :model="model"
              model-key="hasMainPropulsionAvailable"
              :question="$t('repair.other.propulsion')"
              :validators="{ required: true }"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              data-test="main-propulsion"
            />

            <input-yes-no
              :form="form"
              :model="model"
              model-key="hasSecurityAndHealthStatement"
              :validators="{ required: true }"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              :question="$t('repair.other.safetyStatement.question')"
              data-test="safety-statement"
            />

            <input-file
              v-if="model.hasSecurityAndHealthStatement"
              :form="form"
              :label="$t('repair.other.safetyStatement.label')"
              :model="model"
              :validators="{ files: { maxFiles: 10 } }"
              model-key="attachments"
              data-test="attachment-safety-statement"
            />

            <input-yes-no
              :form="form"
              :model="model"
              model-key="hasHazardousSubstancesWithinSafetyParameter"
              :question="$t('repair.other.hazardousSubstances')"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              :validators="{ required: true }"
              data-test="dangerous-goods"
            />

            <input-yes-no
              :form="form"
              :model="model"
              model-key="lngDrivenShip"
              :validators="{ required: true }"
              :question="$t('repair.other.lng')"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              data-test="lng-driven"
            />
          </field-set>

          <field-set
            v-if="model.lngDrivenShip"
            class="boxed-fieldset"
            :legend="$t('repair.lng.title')"
            :description="$t('repair.lng.description')"
            data-test="lng-driven"
          >
            <input-checkbox
              :form="form"
              :model="model"
              model-key="lngWorkInProximityOfTanks"
              :checkbox-label="$t('repair.lng.proximityTanks')"
              data-test="proximity-of-tanks"
            />

            <input-number
              :form="form"
              :model="model"
              model-key="lngQuantityOnBoard"
              :validators="{
                required: true,
                min: { minValue: 0 },
                max: { maxValue: 20000, messageTranslationKey: 'repair.lng.quantity.message' },
              }"
              :label="$t('repair.lng.quantity.label')"
              append="m³"
              :max-decimals="0"
              :placeholder="$t('repair.lng.quantity.placeholder')"
            />

            <input-textarea
              :form="form"
              :model="model"
              model-key="lngBunkerTanksLocation"
              :validators="{ required: true, maxLength: { length: 512 } }"
              :label="$t('repair.lng.location.label')"
              :label-hidden="false"
              :placeholder="$t('repair.lng.location.placeholder')"
            />

            <input-number
              :form="form"
              :model="model"
              model-key="lngBunkerTanksMBarPressure"
              :validators="{
                required: true,
                min: { minValue: 0 },
                max: { maxValue: 20000, messageTranslationKey: 'repair.lng.quantity.message' },
              }"
              :label="$t('repair.lng.bunker')"
              :placeholder="$t('repair.lng.quantity.placeholder')"
              :max-decimals="0"
              append="mBar"
            />

            <input-number
              :form="form"
              :model="model"
              model-key="lngSafetyValveOpeningsMBarPressure"
              :validators="{
                required: true,
                min: { minValue: 0 },
                max: { maxValue: 20000, messageTranslationKey: 'repair.lng.quantity.message' },
              }"
              :label="$t('repair.lng.valve')"
              :placeholder="$t('repair.lng.quantity.placeholder')"
              :max-decimals="0"
              append="mBar"
            />

            <input-checkbox
              :form="form"
              :model="model"
              model-key="lngBoilOffControlOperational"
              :checkbox-label="$t('repair.lng.boilOff')"
              data-test="boil-off-control-operational"
            />

            <input-checkbox
              :form="form"
              :model="model"
              model-key="lngEmissionPreventionMeasuresTaken"
              :checkbox-label="$t('repair.lng.emission')"
              data-test="emission-prevention"
            />
          </field-set>
        </echo-form>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime, Duration } from 'luxon';
import InputYesNo from 'poronline-shared-vue-components/components/form/input-yes-no';
import FieldSeparator from 'poronline-shared-vue-components/components/form/field-separator';
import FieldSet from 'poronline-shared-vue-components/components/form/field-set';
import InputUcrn from 'poronline-shared-vue-components/components/form/input-ucrn';
import InputSelect from 'poronline-shared-vue-components/components/form/input-select';
import InputDate from 'poronline-shared-vue-components/components/form/input-date';
import InputTextarea from 'poronline-shared-vue-components/components/form/input-textarea';
import InputRadio from 'poronline-shared-vue-components/components/form/input-radio';
import InputCheckbox from 'poronline-shared-vue-components/components/form/input-checkbox';
import InputNumber from 'poronline-shared-vue-components/components/form/input-number';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import EchoForm from '../../components/form/echo-form';
import InputFile from '../../components/form/input-file';
import ShipSummary from '../../components/form/ship-summary';
import withVisit from '../../components/form/with-visit';
import Page from '../../components/page/page';

export default {
  mixins: [withVisit],
  components: {
    InputYesNo,
    Page,
    PageRow,
    PageColumn,
    EchoForm,
    FieldSeparator,
    FieldSet,
    InputUcrn,
    InputSelect,
    InputTextarea,
    InputRadio,
    InputDate,
    InputCheckbox,
    InputNumber,
    InputFile,
    ShipSummary,
  },
  props: {
    copy: Object,
  },
  data() {
    const model = {};

    if (this.copy) {
      Object.assign(model, this.copy);
    }

    if (this.$route.query.ucrn) {
      model.ucrn = this.$route.query.ucrn;
    }

    return { model, form: {} };
  },
  computed: {
    ...mapGetters({
      thisMinute: 'THIS_MINUTE',
    }),
    vesselForSummary() {
      return this.model.ucrn && this.form?.ucrn?.$valid
        ? {
            shipName: this.selectedVessel?.shipName,
            shipNumber: this.selectedVessel?.shipNumber,
            category: this.selectedVessel?.category,
            length: this.selectedVessel?.length,
            beam: this.selectedVessel?.beam,
          }
        : undefined;
    },
    startDateConfig() {
      return {
        enableTime: true,
        minDate: this.thisMinute.toJSDate(),
      };
    },
    endDateConfig() {
      return {
        enableTime: true,
        minDate: (this.model?.from ? DateTime.fromISO(this.model.from) : DateTime.local()).startOf('day').toJSDate(),
      };
    },
    showOwnerPermission() {
      if (!(this.model?.from && this.model?.until && this.form?.from?.$valid && this.form?.until?.$valid)) {
        return false;
      }

      return (
        DateTime.fromISO(this.model.until).diff(DateTime.fromISO(this.model.from)) >
        Duration.fromObject({ days: this.durationDaysThreshold })
      );
    },
    durationDaysThreshold() {
      return 7;
    },
  },
};
</script>
